import { getDCAContractAddress } from '@helpers/chains';
import { useQuery } from '@tanstack/react-query';
import { Config, ConfigResponse } from 'src/interfaces/dca/response/get_config';
import { ChainId } from '@models/ChainId';
import { useChainId } from '@hooks/useChainId';
import { useCosmWasmClient } from '@hooks/useCosmWasmClient';

export function useConfig(injectedChainId?: ChainId): Config | undefined {
  const { chainId: currentChainId } = useChainId();
  const { cosmWasmClient } = useCosmWasmClient();
  const chainId = injectedChainId ?? currentChainId;

  const { data: response } = useQuery<ConfigResponse>(
    ['config', chainId],
    () =>
      cosmWasmClient!.queryContractSmart(getDCAContractAddress(chainId!), {
        get_config: {},
      }),
    {
      enabled: !!chainId && !!cosmWasmClient,
      meta: {
        errorMessage: 'Error fetching config',
      },
    },
  );

  return response && response.config;
}
